import React from 'react';

import {
  Container, Grid, Typography, Link as MuiLink,
} from '@mui/material';
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo';
import { Link } from 'gatsby';
import Layout, { LayoutContext } from '../../components/Layout';
import golfGroup from '../../img/golf-group-on-squaddie.png';
import appStore from '../../img/app-store.svg';
import playStore from '../../img/play-store.png';

function PlayPage() {
  const { mobileMode } = React.useContext(LayoutContext);
  return (
    <div>
      <Container maxWidth="lg" sx={mobileMode ? { py: 4 } : { pt: 3, pb: 14 }}>
        <Grid container direction="column" rowGap={3}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction={mobileMode ? 'column' : 'row'}
            rowGap={3}
          >
            <Grid item xs={12} md={4}>
              <img
                src={golfGroup}
                alt="Example of golf group in the application over green background"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container direction="column" rowGap={2}>
                <Typography
                  variant="h1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Viga',
                        fontSize: '36px',
                        fontWeight: '400',
                        lineHeight: '45px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                      }
                      : {
                        fontFamily: 'Viga',
                        fontSize: '67px',
                        fontWeight: '400',
                        lineHeight: '90px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                      }
                  }
                >
                  Squaddie is available to download!
                </Typography>
                <Typography
                  variant="body1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Mulish',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '35px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: '#8C959F',
                      }
                      : {
                        fontFamily: 'Mulish',
                        fontSize: '28px',
                        fontWeight: '400',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#8C959F',
                      }
                  }
                >
                  Download Squaddie today for Apple or Android.
                </Typography>
                <Link to="https://play.squaddie.co">
                  <MuiLink>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: 'Mulish',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '35px',
                        letterSpacing: '0em',
                        color: '#8C959F',
                      }}
                    >
                      If you would rather use our Web App, continue to the web app by clicking here.
                    </Typography>
                  </MuiLink>
                </Link>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                alignContent="center"
                columnGap={4}
                sx={{ pt: 5 }}
              >
                <a href="https://apps.apple.com/us/app/squaddie/id6451010321">
                  <img
                    src={appStore}
                    alt="Download Squaddie on the Apple App Store"
                    style={{ height: '68px' }}
                  />
                </a>

                <a href="https://play.google.com/store/apps/details?id=io.ionic.Squaddie">
                  <img
                    src={playStore}
                    alt="Download Squaddie on the Android Play Store"
                    style={{ width: '250px' }}
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function PlayIndexPage() {
  return (
    <Layout>
      <GatsbySeo
        title="Play Squaddie | Squaddie - The Caddie for your Golf Squad"
        description="Organize and play your Tee Times on Squaddie today. Download the Apple App Store app, the Google Android Play Store app, or use our own web app. Squaddie is accesible on almost every device."
        canonical="https://www.squaddie.co/play"
      />
      <JsonLd
        url="https://www.squaddie.com/play"
        datePublished="2023-08-27T08:00:00+08:00"
        // dateModified="2023-03-10T09:00:00+08:00"
        authorName="Squaddie"
        authorType="Organization"
        description="Organize and play your Tee Times on Squaddie today. Download the Apple App Store app, the Google Android Play Store app, or use our own web app. Squaddie is accesible on almost every device."
        overrides={{
          '@type': 'SoftwareApplication',
          name: 'Squaddie',
          applicationCategory: 'UtilitiesApplication',
        }}
      />
      <PlayPage />
    </Layout>
  );
}

export default PlayIndexPage;
